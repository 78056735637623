<template>
    <div class="login-container">
        <div class="title mr-b3">{{"访客注册"|language}}</div>
        <Form
            :model="formItem"
            :label-width="80"
            ref="formInline"
            :rules="ruleInline"
        >
            <FormItem
                :label="'手机号：'|language"
                prop="tel"
            >
                <Input
                    v-model="formItem.tel"
                    style="width:320px;"
                    :placeholder="'请输入手机号'|language"
                ></Input>
            </FormItem>
            <!-- <FormItem
                label="验证码："
                prop="code"
            >
                <Input
                    v-model="formItem.code"
                    style="width:320px;"
                    placeholder="请输入验证码"
                >
                <Button
                    style="width:150px;"
                    slot="append"
                    @click="getCode"
                >{{text}}</Button></Input>
            </FormItem> -->
            <FormItem :label="'邀请码：'|language">
                <Input
                    v-model="formItem.invate"
                    style="width:320px;"
                    :placeholder="'请输入邀请码'|language"
                ></Input>
            </FormItem>
            <FormItem>
                <Button
                    type="primary"
                    style="width:320px;"
                    size="large"
                    @click="handleSubmit('formInline')"
                >{{"提交"|language}}</Button>
            </FormItem>
        </Form>

    </div>
</template>

<script>
import { languageTranslation } from "../../assets/util/language";
export default {
  data() {
    return {
      formItem: {
        tel: "",
        code: "",
        invate: ""
      },
      text: "获取验证码",
      time: 3,
      flag: false,
      ruleInline: {
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            type: "string",
            len: 11,
            message: "手机号格式错误",
            trigger: "blur"
          }
        ],
        // code: [
        //   { required: true, message: "请输入验证码", trigger: "blur" }
        //   // { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
        // ],
        invate: [{ required: true, message: "请输入邀请码", trigger: "blur" }]
      }
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          let res = await this.$http.post("/user/register", {
            phone: this.formItem.tel,
            inviteCode: this.formItem.invate
          });
          if (res == "success") {
            await this.$Modal.confirm({
              title: languageTranslation("注册成功"),
              content: languageTranslation("是否立即跳转到登录页面？"),
              okText: languageTranslation("确定"),
              cancelText: languageTranslation("取消"),
              onOk: () => {
                this.$router.push("/common/login");
              }
            });
          }
        } else {
          this.$Message.error("Fail!");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 25px;
    font-weight: 600;
    opacity: 0.9;
  }
}
</style>